<template>
  <div>
    <div class='box_content'>
    <div class="content_box">
        <div style="display: flex;flex-direction: row;align-content: center;">
            <div>
                <a-card style="width: 90px;height: 400px;">
                    <div v-for="(item,index) in product.imgs" v-bind:key="index">
                        <div v-if="index < 4" class="image-container img-item" @click="action_click(index)" v-bind:class="{ 'img-item-selected': checkSelected(index)}">
                            <img :src="item.img" style="width: 60px;"/>
                        </div>

                        <div v-if="index == 4" class="image-container" @click="action_more()">
                            <img :src="item.img" style="width: 60px;"/>
                            <div class="image-overlay-permanent"><strong class="image-info">+{{product.imgs.length-4}}</strong></div>
                        </div>
                    </div>
                </a-card>
            </div>
            <div style="width: 400px;">
                <a-carousel arrows :dots="false" dots-class="slick-dots slick-thumb" ref="imgs">
                    <div v-for="(item,index) in product.imgs" v-bind:key="index" @click="action_more(index)">
                        <img v-if="index <4" :src="item.img" style="height: 400px;cursor: pointer;"/>
                    </div>
                </a-carousel>
            </div>
        </div>
        <div style="width: 100%;padding-left: 15px;">
            <a-row>
                <a-col :span="24">
                    <h3 class="product-info__heading">{{product.title}}</h3>
                </a-col>
                <a-col :span="24">
                    <a-divider />
                </a-col>
                <!-- <a-col :span="24" style="padding-bottom: 16px;">
                    <a-col :span="6">
                        <span class="product-options__row">Price</span>
                    </a-col>
                    <a-col :span="18"><span class="product-options__price">CN¥57.97 - CN¥79.42</span></a-col>
                </a-col> -->
                <a-col :span="24" style="padding-bottom: 16px;">
                    <a-col :span="6">
                        <span class="product-options__row">Color</span>
                    </a-col>
                    <a-col :span="18">
                        <div style="display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;">
                            <div v-for="(item,index) in product.product_variations" v-bind:key="index" @click="action_click(item)">
                                 <a-popover>
                                    <template slot="content">
                                    <p>{{item.color}}</p>
                                    </template>
                                    <a-button type="primary" style="padding: 0;margin-right: 8px;background-color: #ccc;border: 0;">
                                        <img :src="item.img" style="max-width: 40px;max-height: 40px;">
                                    </a-button>
                                </a-popover>
                            </div>
                        </div>
                    </a-col>
                </a-col>
                <a-col :span="24" style="padding-bottom: 16px;">
                    <a-col :span="6">
                        <span class="product-options__row">Size</span>
                    </a-col>
                    <a-col :span="18">
                        <div style="display: flex;flex-direction: row;flex-wrap: wrap;align-items: center;">
                            <div v-for="(item,index) in sizelist" v-bind:key="index" @click="action_click(item)">
                                <a-tag>{{item}}</a-tag>
                            </div>
                        </div>
                    </a-col>
                </a-col>
            </a-row>
        </div>
    </div>
    <div>
        <div class="shareTip"><span  
           v-clipboard:copy="urlPath"
           v-clipboard:success="onCopy"
           v-clipboard:error="onError"
        ><a-icon type="share-alt" /></span></div>
        <a-divider orientation="left">Product Description</a-divider>
        <div v-html="product.description" style="width:100%;"></div>
    </div>
  </div>
    <div class="slider-overlay" v-show="imgsShow">
        <div class="slider-close-btn" @click="action_close()"><a-icon type="close" /></div>
        <div class="slider-body">
            <div class="slider-overlay__button" @click="previousImg()"><a-icon type="left" /></div>
            <article>
                <div class="img-container-slider">
                    <img :src="showImgSrc"/>
                </div>
            </article>
            <div class="slider-overlay__button" @click="nextImg()"><a-icon type="right" /></div>
        </div>
        <div class="slider-footer slider-is-open">
            <strong class="info-bar">
                {{pagesTip}}
            </strong>
        </div>
    </div>
  </div>
</template>

<script>
import {getProductCategories,addProduct,editProduct,getProductDetail} from '@/network/prorequest'
export default {
    name: "Product",
    components: {},
    directives: {},
    data() {
        return {
            sizelist:{},
            pagesTip:'',
            imgsShow:false,
            imgsIndex:0,
            customPaging:[],
            baseUrl:'/img/',
            flag:0,
            product:[],
            showImgSrc:'',
            urlPath:'',
        }
    },
    created(){
        this.urlPath = window.location.href;
        if(this.$route.query.product_id){
            getProductDetail(this.$route.query.product_id)
            .then(res=>{
                if(res.header.code==200){
                    this.product=res.body.product;
                    let l = res.body.product.product_variations.length;
                    let i = 0;
                    for(i;i<l;i++){
                        this.sizelist[res.body.product.product_variations[i]['size']] =  res.body.product.product_variations[i]['size'];
                    }
                    console.log(this.sizelist);
                }
            });
        }
    },
    methods: {
        onCopy: function (e) {
            this.$message.success('copy success');
        },
        onError: function (e) {
            this.$message.error('copy fail');
        },
        previousImg(){
            if(this.imgsIndex >0){
                --this.imgsIndex;
            }else{
                this.imgsIndex = this.product.imgs.length-1;
            }
            this.showImgSrc = this.product.imgs[this.imgsIndex].img;
            this.pagesTip = (this.imgsIndex+1)+'/'+this.product.imgs.length;
        },
        nextImg(){
            if(this.imgsIndex < (this.product.imgs.length-1)){
                ++this.imgsIndex;
            }else{
                this.imgsIndex = 0;
            }
            this.pagesTip = (this.imgsIndex+1)+'/'+this.product.imgs.length;
            this.showImgSrc = this.product.imgs[this.imgsIndex].img;
        },
        getImgUrl(i) {
            return `${this.baseUrl}test${i + 1}.jpg`;
        },
        action_click(i){
            this.flag = i;
            this.$refs.imgs.goTo(i,true);
        },
        action_more(i=0){
            this.pagesTip = (i+1)+'/'+this.product.imgs.length;
            this.showImgSrc = this.product.imgs[i].img;
            this.imgsShow = true;
        },
        action_close(){
            this.imgsShow = false;
        },
        checkSelected(i){
            if(this.flag == i){
                return true;
            }
            return false;
        },
    },
}
</script>

<style>
    .shareTip{
        font-size: 26px;
        color: #d70924;
        text-align: right;
        padding-right: 15px;
    }
    .shareTip span{
        cursor: pointer;
    }
    .slider-footer{
        display: -moz-flex;
        display: flex;
        -moz-justify-content: center;
        justify-content: center;
        position: absolute;
        bottom: 0;
        flex-direction: column;
        align-items: center;
    }
    .slider-footer .info-bar{
        color: #6f6f91;
        margin-bottom: 16px;
    }
    .img-container-slider{
        display: -moz-flex;
        display: flex;
        -moz-justify-content: center;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .img-container-slider img{
        max-width: 500px;
        max-height: 500px;
        object-fit: contain;
    }
    .slider-overlay__button{
        position: relative;
        display: inline-flex;
        align-items: center;
        text-align: center;
        white-space: nowrap;
        vertical-align: middle;
        cursor: pointer;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        color: white;
        font-size: 26px;
    }
    .slider-close-btn{
        align-self: flex-end;
        color: white;
        font-size: 26px;
        cursor: pointer;
    }
    .slider-body{
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -ms-user-select: none;
        user-select: none;
        display: -moz-flex;
        display: flex;
        -moz-justify-content: space-between;
        justify-content: space-between;
        align-self: stretch;
        flex-direction: row;
        width: 100%;
        height: 100%;
        margin-bottom: 70px;
        align-items: center;
    }
    .slider-overlay{
        display: -moz-flex;
        display: flex;
        -moz-justify-content: space-between;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        position: fixed;
        flex-direction: column;
        align-items: center;
        z-index: 3000;
        left: 0;
        top: 0;
        overflow: auto;
        background: rgba(0,0,0,.75);
        padding: 16px 16px 0 16px;
    }
    .ant-card-bordered{
        border:0;
    }
    .ant-popover-inner-content{
        padding: 0px 4px;
        background: black;
        color: white;
        border-radius: 2px;
    }
    .content_box{
        display: flex;
        flex-direction: row;
        align-content: center;
    }    
    @media screen and (max-width: 888px){
        .content_box{
            flex-direction: column !important;
        }
    }
    .image-container{
        margin: 0 8px 8px 0;
        position: relative;
        width: 60px;
        height: 60px;
        cursor: pointer;
        max-width: 400px;
        max-height: 400px;
        overflow: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #ededf2;
        border-radius: 4px;
        border: none;
    }
    .image-overlay-permanent{
        display: flex;
        justify-content: center;
        cursor: pointer;
        background: rgba(0,0,0,.5);
        color: #fff;
        position: absolute;
        z-index: 10;
        border-radius: inherit;
        transition: opacity .25s ease;
        width: 60px;
        height: 60px;
        top: 0;
        align-items: center;
    }
    .image-info {
        font-size: 19px;
    }
    strong, .bold {
        font-weight: 600;
    }
    .img-item-selected {
        border: 1px solid #e87282;
    }
    .img-item:hover {
        border: 1px solid #e87282;
    }
    .product-info__heading{
        padding-top: 15px;
        color: #272742;
        font:700 16px Arial,"microsoft yahei";
    }
    .product-options__row{
        padding-bottom: 16px;
        color: #6f6f91;
        word-break: break-word;
    }
    .product-options__price {
        color: #a10003;
        font-weight: 600;
        font-size: 21px;
        line-height: 28px;
    }
</style>